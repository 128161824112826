import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme, { span } from '../../style/theme'
import RichText from '../RichText'
import isEmpty from 'lodash/isEmpty'
import { useDarkSlice } from '../HeaderContextProvider'
import cn from 'classnames'

import Timer from './Timer'
import Link from '../Link'
import { resolveLink } from '../../helpers/resolveLink'
import ResponsiveImage from '../ResponsiveImage'
import Speakers from '../Speakers'

export default ({ slice }) => {
  const hasHero = !isEmpty(false)
  const degrees = 6.5
  const adjacentSide = Math.tan(degrees * Math.PI / 180) * 100
  const classes = useStyles({ adjacentSide, hasHero })
  const sectionRef = useDarkSlice()
  const { old_agenda_slice: agenda, heroImage } = slice
  const { title, agenda_file: attendeesFile, endLine } = agenda

  const resolvedFile = useMemo(() => attendeesFile && attendeesFile.file ? resolveLink(attendeesFile.file) : null, [attendeesFile])

  return (
    <section className={classes.section} ref={sectionRef}>
      <div className={classes.rows}>
        <div className={classes.cardWrapper}>
          {slice.venue && (
            <div className={classes.card}>
              <span className={classes.rowLabel}>Venue</span>
              <span className={classes.stage}>{slice.venue}</span>
            </div>
          )}
          {slice.date && (
            <div className={classes.card}>
              <span className={classes.rowLabel}>Date</span>
              <span className={cn(classes.stage, classes.date)}>
                18th-19th October
                <span className={classes.year}>2024</span>
              </span>
            </div>
          )}
          <div className={classes.card}>
            <Timer deadline={new Date(slice.CountdownTimer)} />
          </div>
        </div>
        <div>
          <div className={classes.container}>
            <div className={classes.desktopView}>
              <div className={classes.backgroundImageContainer}>
                <div className={classes.speakersTitle}>{slice.speakersTitle}</div>
                <div className={classes.imageWrapper}>
                  <ResponsiveImage {...heroImage} />
                </div>

                <div className={classes.speakersWrapper}>
                  {slice.speakers.map((speaker, index) => (
                    <div key={index} className={classes.speakerCard}>
                      <ResponsiveImage {...speaker.photo} style={{ backgroundColor: 'transparent' }} />
                      <div className={classes.speakerName}>
                        {speaker.name.split(' ').map((word, index) => {
                          const formattedWord = word.includes('.') ? word.replace(/\./g, '. ') : word
                          return <span key={index}>{formattedWord}</span>
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* for mobile view */}
            <div className={classes.mobileView}>
              <Speakers data={slice} />
            </div>
          </div>
        </div>

        <div className={classes.agendaWrapper}>
          {endLine && <p className={classes.endLine}>{endLine}</p>}
          {resolvedFile && (
            <Link className={classes.fileDownload} link={resolvedFile} nonLinkTag='div'>
              <span className={classes.highlighted}>{title}</span>
              <svg width='16' height='16' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M24.7884 10.1019C25.243 9.64734 25.243 8.91028 24.7884 8.45568L17.3802 1.0475C16.9256 0.592894 16.1886 0.592894 15.7339 1.0475C15.2793 1.5021 15.2793 2.23916 15.7339 2.69376L22.319 9.27881L15.7339 15.8639C15.2793 16.3185 15.2793 17.0555 15.7339 17.5101C16.1886 17.9647 16.9256 17.9647 17.3802 17.5101L24.7884 10.1019ZM0.683594 10.4429L23.9653 10.4429L23.9653 8.11473L0.683594 8.11473L0.683594 10.4429Z' fill='white' />
              </svg>
            </Link>)}
        </div>

        <div className={classes.bioWrapper}>
          {slice.detailedText && (
            <RichText
              content={slice.detailedText.text}
              className={classes.bio}
            />
          )}
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({

  section: {
    marginTop: 'auto',
    padding: [24],
    backgroundColor: theme.colors.secondary,
    [theme.breakpoints.up('md')]: {
      padding: [50]
    }
  },

  bio: {
    marginTop: '12px',
    color: theme.colors.white,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: '0'
    }
  },
  rows: {
    display: 'flex',
    flexDirection: 'column'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Object Sans',
    alignItems: 'center',
    color: 'white',
    padding: 16,
    border: '1px solid rgb(255 255 255 / 20%)',
    width: '100%',
    marginBottom: '24px',
    background: 'rgb(255 255 255 / 5%)',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      width: '30%'
    }
  },
  rowLabel: {
    fontFamily: 'Object Sans',
    color: theme.colors.primary,
    paddingBottom: '12px'
  },
  stage: {
    fontFamily: 'Object Sans',
    fontSize: '20px',
    textAlign: 'center',
    [theme.breakpoints.up('fab')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '22px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '24px'
    }
  },
  date: {
    textTransform: 'none',
    fontSize: '22px',
    [theme.breakpoints.up('fab')]: {
      fontSize: '22px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '26px'
    }
  },
  year: {
    display: 'block'
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      flexDirection: 'row'
    }
  },
  bioWrapper: {
    width: '75%',
    margin: '0 auto',
    // border: '1px solid rgb(255 255 255 / 20%)',
    // background: 'rgb(255 255 255 / 5%)',
    // padding: [24],
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '24px'
    }
  },
  agendaWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '32px'
  },
  endLine: {
    color: 'white',
    marginBottom: '12px',
    [theme.breakpoints.up('md')]: {
      fontSize: '23px'
    }
  },
  fileDownload: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    padding: [8, 24],
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center'
  },
  desktopView: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  mobileView: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  container: {
    margin: '2rem 0',
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  backgroundImageContainer: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 0
  },
  speakersTitle: {
    position: 'absolute',
    top: '2.5rem',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    color: 'white',
    zIndex: 10,
    fontSize: '1.875rem'
  },
  imageWrapper: {
    width: '100%',
    maxWidth: '2500px',
    margin: '0 auto',
    height: 'auto'
  },
  speakersWrapper: {
    position: 'absolute',
    bottom: '-0.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    maxWidth: '2500px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10
  },
  speakerCard: {
    position: 'relative',
    bottom: '-0.25rem',
    overflow: 'hidden',
    height: 'auto',
    width: 'calc((2500px - 3rem) / 7)'
  },
  speakerName: {
    position: 'absolute',
    bottom: '1rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    color: 'white',
    zIndex: 20,
    fontSize: '1rem'
  }

}, { name: 'founderRetreatHero' })
