import React from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from './ResponsiveImage'

const ROW_STRUCTURE = [3, 2, 3]

const Speakers = ({ data }) => {
  const classes = useStyles()
  let startIndex = 0

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {data.speakersTitle}
      </div>
      <div className={classes.speakersWrapper}>
        {ROW_STRUCTURE.map((itemsPerRow, rowIndex) => {
          const rowSpeakers = data.speakers.slice(startIndex, startIndex + itemsPerRow)
          startIndex += itemsPerRow

          return (
            <div key={rowIndex} className={classes.speakerRow}>
              {rowSpeakers.map((speaker, index) => (
                <div key={index} className={classes.speakerCard}>
                  <div className='relative'>
                    <ResponsiveImage
                      {...speaker.mobileBackgroundImage}
                      style={{ backgroundColor: 'transparent' }}
                    />
                  </div>
                  <div className={classes.speakerImageWrapper}>
                    <ResponsiveImage
                      {...speaker.mobileImage}
                      style={{ backgroundColor: 'transparent' }}
                      className={classes.speakerImage}
                    />
                  </div>

                  <div className={classes.speakerName}>
                    {speaker.name}
                  </div>
                </div>
              ))}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  title: {
    margin: '0.5rem 0 2rem 0',
    zIndex: 12,
    left: '50%',
    textAlign: 'center',
    color: 'white',
    fontSize: '1.25rem'
  },
  speakersWrapper: {
    width: '100%',
    margin: '0 auto',
    zIndex: 10
  },
  speakerRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '2.5rem'
  },
  speakerCard: {
    position: 'relative',
    // overflow: 'hidden',
    height: 'auto',
    width: '30%'
  },
  speakerName: {
    // marginTop: '0.75rem',
    textAlign: 'center',
    color: 'white',
    fontSize: '0.75rem',
    zIndex: 20,
    position: 'relative',
    bottom: '-1.5rem'
  },
  speakerImageWrapper: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    bottom: '-2px'
  },
  speakerImage: {
    position: 'static'
  }
})

export default Speakers
